import { Button, Col, Menu, Row } from 'antd';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import Avatar from '#components/Avatar';
import { User } from '#models/model-types/UserModel';

import Icon from '../Icon';
import { Text } from '../Typography';

const { SubMenu } = Menu;

interface SidebarMenuProps {
  selectedKeys: string[];
  user?: User;
  logout?: () => Promise<void>;
  becomeCreator?: () => void;
  allowChangePassword?: boolean;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  selectedKeys,
  user,
  logout,
  becomeCreator,
  allowChangePassword = false,
}) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  if (!user?.uid) {
    return null;
  }

  return (
    <Menu className="sidebar-menu" selectedKeys={selectedKeys}>
      <Menu.Item
        key="profile"
        icon={<Icon name="user" width={28} height={28} />}
        onClick={() => router.push('/profile')}
      >
        {t('menu.myProfile')}
      </Menu.Item>

      {user.isCreator && (
        <>
          <Menu.Item key="creator" icon={<Icon name="star" width={28} height={28} />}>
            {t('menu.creator')}
          </Menu.Item>

          <Menu.Item key="creator-profile" onClick={() => router.push('/creator/profile')} className="pl-60">
            {t('menu.creatorProfile')}
          </Menu.Item>

          <Menu.Item key="creator-subscribers" onClick={() => router.push('/creator/subscribers')} className="pl-60">
            {t('menu.mySubscribers')}
          </Menu.Item>

          <Menu.Item key="creator-income" onClick={() => router.push('/creator/income')} className="pl-60">
            {t('menu.income')}
          </Menu.Item>
        </>
      )}

      <Menu.Item
        key="purchases"
        icon={<Icon name="purchase" width={28} height={28} />}
        onClick={() => router.push('/purchases')}
      >
        {t('menu.purchases')}
      </Menu.Item>

      {allowChangePassword && (
        <Menu.Item
          key="change-password"
          icon={<Icon name="lock" width={28} height={28} />}
          onClick={() => router.push('/update-password')}
        >
          {t('menu.changePassword')}
        </Menu.Item>
      )}

      {/* !user.isCreator && (
        <>
          <Menu.Divider />
          <Menu.Item key="become-creator" onClick={becomeCreator}>
            <div className="p-16 flex flex-col space-y-8 rounded-16 bg-primary bg-opacity-20">
              <Text preset="regular14" className="text-center min-w-0 whitespace-normal">
                {t('msg.becomeCreator')}
              </Text>

              <Button type="primary" shape="round">
                {t('title.getStarted')}
              </Button>
            </div>
          </Menu.Item>
        </>
      ) */}

      <Menu.Item key="logout" onClick={logout} className="logout">
        <Text preset="medium16" className="text-red">
          {t('menu.logOut')}
        </Text>
      </Menu.Item>
    </Menu>
  );
};

export default SidebarMenu;
