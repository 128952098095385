import { Divider, Layout } from 'antd';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { observer } from 'mobx-react-lite';
import { getSnapshot } from 'mobx-state-tree';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import MainFooter from '#components/MainFooter';
import MainHeader from '#components/MainHeader';
import MainMenu from '#components/MainMenu';
import SidebarMenu from '#components/SidebarMenu';
import { useStore } from '#models';
import { User } from '#models/model-types/UserModel';

const { Content, Header, Footer } = Layout;

interface MainLayoutProps {
  className?: string;
  footerVisible?: boolean;
  leftSideBar?: boolean;
}

const MainLayout = observer(
  ({ className, footerVisible = false, leftSideBar = false, children }: React.PropsWithChildren<MainLayoutProps>) => {
    const router = useRouter();
    const { t } = useTranslation('common');
    const {
      authStore: { user, logout, getFirebaseUser },
    } = useStore();
    const firebaseUser = getFirebaseUser();
    const loggedUser = user && getSnapshot<User>(user);

    const [menuVisible, setMenuVisible] = React.useState(false);
    const [sticky, setSticky] = React.useState(false);

    const handleLogout = React.useCallback(async () => {
      try {
        await logout();
      } catch (error) {
      } finally {
        router.push('/signin');
      }
    }, [router]);

    const handleBecomeCreator = React.useCallback(() => {
      router.push(`/application?uid=${loggedUser?.uid}`);
    }, [loggedUser?.uid]);

    const selectedKeys: string[] = React.useMemo(() => {
      if (router.pathname === '/' || router.pathname === '/home') {
        return ['home'];
      }
      if (router.pathname === '/creators') {
        return ['creators'];
      }
      if (router.pathname === '/how-it-works') {
        return ['how-it-works'];
      }
      if (router.pathname === '/giving-back') {
        return ['giving-back'];
      }
      if (router.pathname.startsWith('/profile')) {
        return ['profile'];
      }
      if (router.pathname.startsWith('/creator/profile')) {
        return ['creator-profile'];
      }
      if (router.pathname.startsWith('/creator/subscribers')) {
        return ['creator-subscribers'];
      }
      if (router.pathname.startsWith('/creator/income')) {
        return ['creator-income'];
      }
      if (router.pathname.startsWith('/purchases')) {
        return ['purchases'];
      }
      if (router.pathname.startsWith('/update-password')) {
        return ['change-password'];
      }
      if (router.pathname.startsWith('/signin')) {
        return ['signin'];
      }
      if (router.pathname.startsWith('/signup')) {
        return ['signup'];
      }
      if (router.pathname.startsWith('/forgot-password')) {
        return ['forgot-password'];
      }
      if (router.pathname.startsWith('/reset-password')) {
        return ['reset-password'];
      }
      if (router.pathname.startsWith('/update-password')) {
        return ['update-password'];
      }
      if (router.pathname.startsWith('/set-password')) {
        return ['set-password'];
      }
      if (router.pathname.startsWith('/email-verification')) {
        return ['email-verification'];
      }
      if (router.pathname.startsWith('/application')) {
        return ['application'];
      }
      if (router.pathname.startsWith('/creators/[slug]/checkout')) {
        return ['creator-checkout'];
      }
      return [];
    }, [router.pathname]);

    React.useEffect(() => {
      const classList = document.getElementsByTagName('body')?.[0]?.classList;
      if (menuVisible) {
        classList.add('menu-visible');
      } else {
        classList.remove('menu-visible');
      }
    }, [menuVisible]);

    React.useEffect(() => {
      const handleScroll = throttle(() => {
        setSticky(window.pageYOffset > 70);
      }, 50);
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const allowChangePassword = !!firebaseUser?.providerData?.find((provider) => provider.providerId === 'password');

    return (
      <Layout className={classNames('main-layout', className)}>
        <Header className={classNames('main-layout__header', { 'menu-hidden': !menuVisible, sticky })}>
          <MainHeader
            menuVisible={menuVisible}
            setMenuVisible={setMenuVisible}
            selectedKeys={selectedKeys}
            user={loggedUser}
            logout={handleLogout}
            becomeCreator={handleBecomeCreator}
            allowChangePassword={allowChangePassword}
          />
        </Header>

        <Content className="main-layout__content">
          {leftSideBar ? (
            <div className="container mx-auto flex flex-row flex-nowrap">
              <div className="w-256">
                <SidebarMenu
                  selectedKeys={selectedKeys}
                  user={loggedUser}
                  logout={handleLogout}
                  becomeCreator={handleBecomeCreator}
                  allowChangePassword={allowChangePassword}
                />
              </div>
              <div className="flex-1 overflow-hidden">{children}</div>
            </div>
          ) : (
            children
          )}
        </Content>

        {footerVisible && (
          <Footer className="main-layout__footer">
            <MainFooter />
          </Footer>
        )}
      </Layout>
    );
  },
);

export default MainLayout;
