import { Button, Dropdown } from 'antd';
import classNames from 'classnames';
import intersection from 'lodash/intersection';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import Avatar from '#components/Avatar';
import { useDesktop } from '#components/hooks/breakpoints';
import MainMenu from '#components/MainMenu';
import { User } from '#models/model-types/UserModel';

import Icon from '../Icon';
import { Link } from '../Typography';

const MOBILE_MENU_HIDDEN_KEYS = [
  'signin',
  'signup',
  'forgot-password',
  'reset-password',
  'update-password',
  'set-password',
  'email-verification',
  'application',
  'creator-checkout',
];

interface MainHeaderProps {
  menuVisible: boolean;
  setMenuVisible: (visible: boolean) => void;
  selectedKeys: string[];
  user: User | null;
  logout: () => Promise<void>;
  becomeCreator: () => void;
  allowChangePassword: boolean;
}

const MainHeader: React.FC<MainHeaderProps> = ({
  menuVisible,
  setMenuVisible,
  selectedKeys,
  user,
  logout,
  becomeCreator,
  allowChangePassword,
}) => {
  const { t } = useTranslation('common');
  const isDesktop = useDesktop();
  const router = useRouter();

  const handleSignUpClick = React.useCallback(() => {
    window.localStorage.setItem('redirect', router.asPath);
    router.push('/signup');
  }, [router.asPath]);

  const handleSignInClick = React.useCallback(() => {
    window.localStorage.setItem('redirect', router.asPath);
    router.push('/signin');
  }, [router.asPath]);

  return (
    <div className="main-header h-full flex flex-row items-center">
      <Link href="/">
        <img src={`/images/logo-name.svg`} alt="" width={125} height={30} className="h-30" />
      </Link>

      <div className="flex-1" />

      {isDesktop && intersection(MOBILE_MENU_HIDDEN_KEYS, selectedKeys).length === 0 && (
        <div className="flex flex-row items-center space-x-64">
          <Link preset="medium16" className={classNames('link', { selected: selectedKeys.includes('home') })} href="/">
            {t('common:menu.home')}
          </Link>

          <Link
            preset="medium16"
            className={classNames('link', { selected: selectedKeys.includes('how-it-works') })}
            href="/how-it-works"
          >
            {t('common:menu.howItWorks')}
          </Link>

          <Link
            preset="medium16"
            className={classNames('link', { selected: selectedKeys.includes('creators') })}
            href="/creators"
          >
            {t('common:menu.ourCreators')}
          </Link>

          <Link
            preset="medium16"
            className={classNames('link', { selected: selectedKeys.includes('giving-back') })}
            href="/giving-back"
          >
            {t('common:menu.givingBack')}
          </Link>
        </div>
      )}

      <div className="flex-1" />

      {isDesktop && !user?.uid && intersection(MOBILE_MENU_HIDDEN_KEYS, selectedKeys).length === 0 && (
        <div className="flex flex-row items-center space-x-32">
          <Link
            preset="semibold16"
            className={classNames('link', { selected: selectedKeys.includes('signin') })}
            onClick={handleSignInClick}
          >
            {t('common:menu.signIn')}
          </Link>
          <Button type="primary" shape="round" size="small" onClick={handleSignUpClick}>
            {t('common:menu.signUp')}
          </Button>
        </div>
      )}

      {(!isDesktop || user?.uid) && intersection(MOBILE_MENU_HIDDEN_KEYS, selectedKeys).length === 0 && (
        <Dropdown
          trigger={['click']}
          onVisibleChange={setMenuVisible}
          visible={menuVisible}
          placement="bottomRight"
          arrow={isDesktop}
          overlayClassName="main-menu__overlay"
          overlay={
            <MainMenu
              setMenuVisible={setMenuVisible}
              selectedKeys={selectedKeys}
              user={user}
              logout={logout}
              becomeCreator={becomeCreator}
              allowChangePassword={allowChangePassword}
            />
          }
        >
          {isDesktop ? (
            <Avatar
              url={user?.avatarUrl || user?.creatorPhotoUrl}
              name={user?.fullName || user?.creatorFullName}
              size={32}
            />
          ) : (
            <Button type="text" size="small" className="-mr-8">
              <Icon name={menuVisible ? 'close' : 'hamburger'} />
            </Button>
          )}
        </Dropdown>
      )}
    </div>
  );
};
export default MainHeader;
