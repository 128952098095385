import { Col, Divider, Menu, Row } from 'antd';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { useDesktop } from '#components/hooks/breakpoints';
import { Link, Text } from '#components/Typography';

import Icon from '../Icon';

interface MainFooterProps {}

const MainFooter: React.FC<MainFooterProps> = () => {
  const { t } = useTranslation('common');
  const isDesktop = useDesktop();

  return (
    <div className="container mx-auto border-t-1 border-line">
      <Row gutter={[16, 16]} justify="center" className="px-24 py-16 md:py-32">
        <Col xs={12} md={7} xl={6} xxl={5}>
          <Link preset={isDesktop ? 'regular18' : 'regular16'} href="/terms-conditions" className="footer-link">
            {t('title.termsConditions')}
          </Link>
        </Col>
        <Col xs={12} md={5} xl={4} xxl={4}>
          <Link preset={isDesktop ? 'regular18' : 'regular16'} href="/faqs" className="footer-link">
            {t('title.faqs')}
          </Link>
        </Col>
        <Col xs={12} md={6} xl={5} xxl={4}>
          <Link preset={isDesktop ? 'regular18' : 'regular16'} href="/about-us" className="footer-link">
            {t('title.aboutUs')}
          </Link>
        </Col>
        <Col xs={12} md={6} xl={5} xxl={4}>
          <Link preset={isDesktop ? 'regular18' : 'regular16'} href="/privacy-policy" className="footer-link">
            {t('title.privacyPolicy')}
          </Link>
        </Col>
      </Row>

      <Divider className="my-0" />

      <div className="px-24 py-24 md:py-32 flex flex-row items-center justify-between">
        <Link href="/">
          <img alt="Greenring" src="/images/logo-name.svg" width={104} height={24} />
        </Link>

        <div className="flex flex-row items-center space-x-24">
          <Link href="#">
            <Icon name="facebook-dark" width={24} height={24} />
          </Link>

          <Link href="#">
            <Icon name="twitter-dark" width={24} height={24} />
          </Link>

          <Link href="#">
            <Icon name="instagram-dark" width={24} height={24} />
          </Link>

          <Link href="#">
            <Icon name="youtube-dark" width={24} height={24} />
          </Link>
        </div>

        {isDesktop && (
          <Text preset="regular16" className="block">
            ©{new Date().getFullYear()}. Greenring
            <span className="hidden">v{process.env.NEXT_PUBLIC_APP_VERSION}</span>
          </Text>
        )}
      </div>

      {!isDesktop && (
        <>
          <Divider className="my-0" />

          <Text preset="regular14" className="block py-16 px-24 text-center">
            ©{new Date().getFullYear()}. Greenring
            <span className="hidden">v{process.env.NEXT_PUBLIC_APP_VERSION}</span>
          </Text>
        </>
      )}
    </div>
  );
};
export default MainFooter;
