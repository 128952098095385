import { Button, Col, Menu, Row } from 'antd';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import Avatar from '#components/Avatar';
import { User } from '#models/model-types/UserModel';

import Icon from '../Icon';
import { Text } from '../Typography';

const { SubMenu } = Menu;

interface MainMenuProps {
  selectedKeys: string[];
  user?: User;
  setMenuVisible?: (visible: boolean) => void;
  logout?: () => Promise<void>;
  becomeCreator?: () => void;
  allowChangePassword?: boolean;
}

const MainMenu: React.FC<MainMenuProps> = ({
  selectedKeys,
  user,
  setMenuVisible,
  logout,
  becomeCreator,
  allowChangePassword = false,
}) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const [creatorCollapsed, setCreatorCollapsed] = React.useState(true);

  const handleMenuItemClick = React.useCallback(
    (e) => {
      if (e.key === 'creator') {
        setMenuVisible?.(true);
        setCreatorCollapsed((value) => !value);
      } else {
        setMenuVisible?.(false);
      }
    },
    [setMenuVisible],
  );

  React.useEffect(() => {
    if (
      router.pathname.startsWith('/creator/profile') ||
      router.pathname.startsWith('/creator/subscribers') ||
      router.pathname.startsWith('/creator/income')
    ) {
      setCreatorCollapsed(false);
    }
  }, [router.pathname]);

  if (!user || !user?.uid) {
    return (
      <Menu className="main-menu" onClick={handleMenuItemClick} selectedKeys={selectedKeys}>
        <Menu.Item
          key="login"
          onClick={() => {
            window.localStorage.setItem('redirect', router.asPath);
            router.push('/signin');
          }}
        >
          <Text preset="medium16" className="text-primary">
            {t('menu.signIn')}
          </Text>
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Menu className="main-menu" onClick={handleMenuItemClick} selectedKeys={selectedKeys}>
      <Menu.Item key="avatar" onClick={() => router.push('/profile')}>
        <Row gutter={16} align="middle">
          <Col>
            <Avatar
              url={user?.avatarUrl || user?.creatorPhotoUrl}
              name={user?.fullName || user?.creatorFullName}
              size={64}
            />
          </Col>

          <Col>
            <Text preset="semibold16" className="block">
              {user?.fullName || user?.creatorFullName}
            </Text>
            {user?.username ? (
              <Text preset="regular14" className="block">
                @{user?.username}
              </Text>
            ) : user?.creatorUsername ? (
              <Text preset="regular14" className="block">
                @{user?.creatorUsername}
              </Text>
            ) : null}
          </Col>
        </Row>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item
        key="profile"
        icon={<Icon name="user" width={28} height={28} />}
        onClick={() => router.push('/profile')}
      >
        {t('menu.myProfile')}
      </Menu.Item>

      {user.isCreator && (
        <>
          <Menu.Item key="creator" icon={<Icon name="star" width={28} height={28} />}>
            <span className="inline-flex flex row justify-between items-center" style={{ width: 'calc(100% - 48px)' }}>
              <span className="flex-1">{t('menu.creator')}</span>
              <Icon
                name="arrow-down"
                width={28}
                height={28}
                className={classNames('duration-200', { 'transform -rotate-90': creatorCollapsed })}
              />
            </span>
          </Menu.Item>

          {!creatorCollapsed && (
            <>
              <Menu.Item key="creator-profile" onClick={() => router.push('/creator/profile')} className="pl-68">
                {t('menu.creatorProfile')}
              </Menu.Item>

              <Menu.Item
                key="creator-subscribers"
                onClick={() => router.push('/creator/subscribers')}
                className="pl-68"
              >
                {t('menu.mySubscribers')}
              </Menu.Item>

              <Menu.Item key="creator-income" onClick={() => router.push('/creator/income')} className="pl-68">
                {t('menu.income')}
              </Menu.Item>
            </>
          )}
        </>
      )}

      <Menu.Item
        key="purchases"
        icon={<Icon name="purchase" width={28} height={28} />}
        onClick={() => router.push('/purchases')}
      >
        {t('menu.purchases')}
      </Menu.Item>

      {allowChangePassword && (
        <Menu.Item
          key="change-password"
          icon={<Icon name="lock" width={28} height={28} />}
          onClick={() => router.push('/update-password')}
        >
          {t('menu.changePassword')}
        </Menu.Item>
      )}

      {!user.isCreator && (
        <>
          <Menu.Divider />
          <Menu.Item key="become-creator" onClick={becomeCreator}>
            <div className="p-16 flex flex-row items-center space-x-16 rounded-16 bg-primary bg-opacity-20">
              <Text preset="regular14" className="min-w-0 whitespace-normal">
                {t('msg.becomeCreator')}
              </Text>

              <Button type="primary" shape="round">
                {t('title.getStarted')}
              </Button>
            </div>
          </Menu.Item>
        </>
      )}

      <Menu.Item key="logout" onClick={logout} className="logout">
        <Text preset="medium16" className="text-red">
          {t('menu.logOut')}
        </Text>
      </Menu.Item>
    </Menu>
  );
};

export default MainMenu;
