import AntdAvatar from 'antd/lib/avatar';
import classNames from 'classnames';
import React, { HTMLAttributes, useMemo } from 'react';

import { getInitials } from '#utils/string.util';

interface AvatarProps /*extends HTMLAttributes<HTMLDivElement>*/ {
  url: string;
  size?: number;
  name?: string;
  className?: string;
}

const Avatar: React.FC<AvatarProps> = ({ className, url, name, size, ...divProps }) => {
  const avatarName = useMemo(() => getInitials(name || '').substring(0, 2), [name]);

  return (
    <div className={classNames('inline-block avatar', className)} {...divProps}>
      <AntdAvatar shape="circle" src={url} size={size} alt={avatarName}>
        {avatarName}
      </AntdAvatar>
    </div>
  );
};

export default React.memo(Avatar);
