export const hashCode = (text: string): number => {
  let hash = 0;
  let chr;
  if (text.length === 0) {
    return hash;
  }
  for (let i = 0; i < text.length; i += 1) {
    chr = text.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const getInitials = (text = ''): string => {
  try {
    const initials = text.split(' ').map((w) => w.charAt(0));
    if (initials) {
      return initials.join('');
    }
    return '';
  } catch (err) {
    return '';
  }
};

export const capitalize = (text: string): string => {
  return text.toLowerCase().replace(/\b\w/g, (m) => m.toUpperCase());
};

export const isUrl = (text: string): boolean => {
  if (!text) {
    return false;
  }
  const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const regex = new RegExp(expression);
  return !!text.match(regex);
};

export const isEmail = (text: string): boolean => {
  if (!text) {
    return false;
  }
  const expression =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
  const regex = new RegExp(expression);
  return !!text.match(regex);
};
